.loading-shimmer {
    background-color: #eee;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: inline-block;
    line-height: 1;
    width: 100%;
}
.loading-shimmer.dark {
    background-color: #1f1f1f;
}
.loading-shimmer-animation{
    animation: progress 1.4s linear infinite;
}
  
@keyframes progress {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}